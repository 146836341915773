<template>
    <client-page>

        <sub-visual
        sh="사업분야"
        sc="에프원시큐리티는 전문가들로 이루어져 있습니다."
        tabActive="블록체인 사업"
        icon="/images/sub/visual/sv2-icon.svg"
        bg="/images/sub/visual/sv2.png">
        </sub-visual>

        <section class="sub-section">
            <v-container>
                <v-row class="row--xx-small">
                    <v-col cols="12" lg="5" xl="4" data-aos="fade-right">
                        <h4 class="sub-tit--md font-weight-bold">
                            블록체인 사업
                        </h4>
                    </v-col>
                    <v-col cols="12" lg="7" xl="8" data-aos="fade-left">
                        <p class="sub-tit--sm color-gray line-height-16 mb-8 mb-lg-16">
                            블록체인 사업부는 메타버스,블록체인 관련 기획 및 개발을 담당합니다.

                        </p>
                        <p class="sub-tit--sm color-gray line-height-16">
                            세종대, 건양대, 극동대 등과 업무 협력 체계를 구축함 으로써 산학협력을 통한 기술 개발 <br class="d-none d-xl-block">
                            블록체인 관련 특허를 보유하고 있습니다. <br>
                            - 블록체인을 이용한 공동주택 관리 시스템 및 방법 <br>
                            - 블록체인 플랫폼 사용 주체의 분산식별자 관리시스템
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section class="sub-section bg-grey mb-110 mb-md-140 mb-lg-180">
            <v-container>
                <v-img class="d-none d-md-block mx-auto" max-width="837" src="/images/sub/business/block/block-img.png" data-aos="fade"></v-img>
                <v-img class="d-block d-md-none mx-auto" max-width="670" src="/images/sub/business/block/block-img-mo.png" data-aos="fade"></v-img>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
</style>